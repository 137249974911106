import './home.css';

function Home() {
  return (
    <div className="logo">
      <section className="logoSection">
        <h1 className='yellowColor'>
          All Myanmar News
        </h1>
        <p className='sloganText'>
            One place to read all Myanmar news.
        </p>
        <div class="lineBreak"></div>
        <ul class='downloadIconsList'>
            <li>
                <a href="https://apps.apple.com/us/app/all-myanmar-news/id6737690658" target='_blank'><img src="/images/app_store.svg" alt="Download on the App Store" /></a>
            </li>    
            <li>
                <a href="https://play.google.com/store/apps/details?id=com.allmmnews.app" target='_blank'><img src="/images/play_store.png" alt="Get it on Google Play" /></a>
            </li>    
        </ul>        
      </section>
    </div>
  );
}

export default Home;
